<template lang="html">
  <div class="calendar-item">

    <!-- today -->
    <template v-if="isMonthActive">
      <h5>Today</h5>
      <div class="calendar-items pa-0 pl-2 mb-3" v-for="(item, i) in isToday" :key="`today-${i}`" :class="{'secondary': item.kind == 'proposal', 'primary': item.kind == 'meeting'}">
        <template v-if="true">
          <v-row class="item grey lighten-3 py-3" align="center" justify="space-between" no-gutters @click="goTo(item)">
            <v-col cols="auto">
              <div class="date text-center mr-4 pl-3 grey--text">
                <div class="month">{{$date(item.date).local().format("MMM")}}</div>
                <div class="day">{{$date(item.date).local().format("D")}}</div>
              </div>
            </v-col>
            <v-col>
              <template v-if="item.kind == 'proposal'">
                <div class="top-line text-truncate font-weight-bold">Proposal:
                  <template v-if="!item.data.sendASAP">{{$date.utc(item.date).local().format('h:mm a')}}</template>
                  <template v-else> Meet ASAP</template>
                </div>
                <div class="bottom-line grey--text">{{item.data[whoIsOther(item.data)].firstName || ''}} {{item.data[whoIsOther(item.data)].lastName || ""}}</div>
              </template>
              <template v-else>
                <div class="top-line text-truncate font-weight-bold">Meeting: {{$date.utc(item.data.accepted_Date).local().format('h:mm a')}}</div>
                <div class="bottom-line grey--text">{{item.data[whoIsOther(item.data)].firstName || ''}} {{item.data[whoIsOther(item.data)].lastName || ""}}</div>
              </template>
            </v-col>
            <v-col cols="auto" class="pr-2">
              <v-avatar size="38"><v-img :src="item.data[whoIsOther(item.data)].photoURL_thumb"></v-img></v-avatar>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          Nothing Today
        </template>
      </div>
    </template>
    <template v-else>
      <div class="d-flex align-center justify-center">
        <v-btn class="mb-4 mx-auto" color="primary" text @click="goToToday"> <v-icon>mdi-calendar-today</v-icon>go to today</v-btn>
      </div>
    </template>


    <!-- the loop -->
    <h5>This Month</h5>
    <div v-for="(item, i) in activeMonthDates" :key="`dateMonth-${i}`">
      <div class="calendar-items pl-2 mb-2" v-if="!item.data.sendASAP" :class="{'secondary': item.kind == 'proposal', 'primary': item.kind == 'meeting'}">
        <v-row class="item grey lighten-3 py-3" align="center" justify="space-between" no-gutters @click="goTo(item)">
          <v-col cols="auto">
            <div class="date text-center mr-4 pl-3 grey--text">
              <div class="month">{{$date(item.date).local().format("MMM")}}</div>
              <div class="day">{{$date(item.date).local().format("D")}}</div>
            </div>
          </v-col>
          <v-col>
            <template v-if="item.kind == 'proposal'">
              <div class="top-line text-truncate font-weight-bold">Proposal:
                <template v-if="!item.data.sendASAP">{{$date.utc(item.date).local().format('h:mm a')}}</template>
                <template v-else> Meet ASAP</template>
              </div>
              <div class="bottom-line grey--text">{{item.data[whoIsOther(item.data)].firstName || ''}} {{item.data[whoIsOther(item.data)].lastName || ""}}</div>
            </template>
            <template v-else>
              <div class="top-line text-truncate font-weight-bold">Meeting: {{$date.utc(item.data.accepted_Date).local().format('h:mm a')}}</div>
              <div class="bottom-line grey--text">{{item.data[whoIsOther(item.data)].firstName || ''}} {{item.data[whoIsOther(item.data)].lastName || ""}}</div>
            </template>
          </v-col>
          <v-col cols="auto" class="pr-2">
            <v-avatar size="38"><v-img :src="item.data[whoIsOther(item.data)].photoURL_thumb"></v-img></v-avatar>
          </v-col>
        </v-row>
      </div>
    </div>

    <!-- show no dates -->
    <div class="grey lighten-4 pa-3 rounded" v-if="activeMonthDates.length == 0">
      <h4 class="secondary--text">No meetings this month.</h4>
      <span class="text--disabled">Maybe try another month?</span>
    </div>
</div>
</template>

<script>
export default {
  name:'calendar-item',
  props: ['proposals', 'meetings', 'activeMonth', 'propDateArr', 'meetingDateArr'],
  computed:{
    userId: function (){ return this.$store.state.userId},

    monthDates: function(){
      let pda = this.proposals;
      let mda = this.meetings;
      function compare(a, b) {
        return a.date > b.date ? 1: -1
      }
      let combined = [...pda, ...mda];
      return combined.sort(compare)
    },
    activeMonthDates: function(){
      return this.monthDates.filter(item => {
        process.env.NODE_ENV === "development" ? console.log( 'item', item, (this.$date.utc(item.date).local().month()+1) === this.activeMonth.month, this.$date.utc(item.date).local().year(),  this.$date().year() ) : null;
        return (this.$date.utc(item.date).local().month()+1) === this.activeMonth.month && item.sendASAP == false && this.$date.utc(item.date).local().year() == this.activeMonth.year });
    },
    isMonthActive: function(){
      process.env.NODE_ENV === 'development' ? console.log( 'isMonthACTIVE', (this.$date().month()+1), this.activeMonth ) : null;
      return (this.$date().month()+1) === this.activeMonth.month
    },
    isToday: function(){
      return this.monthDates.filter(item => {
        process.env.NODE_ENV === "development" ? console.log( 'isToday', (this.$date.utc(item.date).local().month()+1) === this.activeMonth, this.$date().isSame(this.$date.utc(item.date).local(),'day') ) : null;
        return (this.$date.utc(item.date).local().month()+1) === this.activeMonth.month && this.$date().isSame(this.$date.utc(item.date).local(),'day') && this.$date.utc(item.date).local().year() == this.activeMonth.year });
      // return this.activeMonthDates.filter(item =>{
      //   process.env.NODE_ENV === "development" ? console.log( 'isSame', this.$date().toDate(), this.$date.utc(item.data.date).local().toDate(), this.$date().isSame(this.$date.utc(item.data.date).local(),'day') ) : null;
      //   return this.$date().isSame(this.$date.utc(item.date).local(),'day')
      // })
    }
  },
  data(){
    return{
      openProposals:[],
      openMeetings:[],
    }
  },
  methods:{
    whoIsUser(val){
      return val.client.userId === this.userId ? 'client' : 'advisor'
    },
    whoIsOther(val){
      return val.client.userId === this.userId ? 'advisor' : 'client'
    },
    goTo(item){
      if(item.kind == 'proposal'){
        this.$router.push({name: 'Proposal', params:{proposalId: item.id}})
      }
      if(item.kind == 'meeting'){
        this.$router.push({name: 'Meeting', params:{meetingId: item.id}})
      }
    },
    goToToday(){
      this.$emit('goto-today', this.$date.utc().local().month()+1)
    }
  }
}
</script>

<style lang="scss" scoped>
.hidden{
  visibility: hidden;
}
.message{
  max-width: 70%;
}
.calendar-items{
  border-radius: 6px;
  overflow: hidden;
  .item{
      .col{
        // background: white;
      }
      .top-line{
        line-height: 18px;
      }
      .bottom-line{
        line-height: 16px;
        font-size: 14px;
      }
  }
  .date{
    .month{
      color: #aaa;
      font-size: 12px;
      line-height: 12px;
    }
    .day{
      font-size: 22px;
      line-height: 28px;
      color: #555;
    }
  }
}
</style>
